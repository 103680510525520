import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class AffiliatedService {
  // url = 'https://oficinavirtual.productospya.com';
  url = environment.apiUrl;
  affiliatedUser: any; // usuario seleccionado en primera pantalla de orden de atencion
  selectedProfessional: any; // usuario seleccionado en primera pantalla de orden de atencion

  constructor(private http: HttpClient) {
    // const parsedUrl = new URL(window.location.href);
    // const baseUrl = parsedUrl.origin + ':5002';
    // this.url = baseUrl;
  }

  getAffiliatedUser() {
    return this.affiliatedUser;
  }
  setAffiliatedUser(user: any) {
    this.affiliatedUser = user;
  }

  getSelectedProfessional() {
    return this.selectedProfessional;
  }
  setSelectedProfessional(professional: any) {
    this.selectedProfessional = professional;
  }

  //Obtiene la data para la consulta de Directorio Medico
  getMedicalDirectoryData(isChevere = false) {
    return Promise.all([
      this.http.get(this.url + `/api/v1/Services?isChevere=${isChevere}`).toPromise(),
      this.http.get(this.url + `/api/v1/States`).toPromise(),
    ]);
  }

  //Obtiene las ciudades de un estado
  getCity(stateCode: number) {
    return this.http
      .get(this.url + `/api/v1/States/${stateCode}/Cities`)
      .toPromise();
  }

  //Obtiene el listado de profesionales
  getProfessionals(serviceCode: string, stateCode: string, cityCode: string, empresaContratante: string) {
    return this.http
      .get(this.url + `/api/v1/Professionals`, {
        params: {
          serviceCode,
          stateCode,
          cityCode,
          esChevere: empresaContratante === "J404854185" ? "true" : "false",
        },
      })
      .toPromise();
  }

  //Obtiene los afiliados
  getAffiliates(documentId: string) {
    return this.http
      .get(this.url + `/api/v1/Affiliates/${documentId}/basicInfo`)
      .toPromise();
  }

  ///api/v1/Affiliates/{documentId}/treatments
  getTreatments(documentId: string, initialDate: any, finalDate: any, empresaContratante: string) {
    return this.http
      .get(this.url + `/api/v1/Affiliates/${documentId}/treatments`, {
        params: {
          documentId,
          initialDate,
          finalDate,
          empresaContratante : empresaContratante === 'J404854185' ? '1': '0',
        },
      })
      .toPromise();
  }

  getStates() {
    return this.http.get(this.url + `/api/v1/States`).toPromise();
  }

  getCities(stateCode: string) {
    return this.http
      .get(this.url + `/api/v1/States/${stateCode}/Cities`)
      .toPromise();
  }

  getCompanies() {
    return this.http.get(this.url + `/api/v1/CompanyGroups`).toPromise();
  }

  affiliatedInfo(documentId: string) {
    return this.http
      .get(this.url + `/api/v1/Affiliates/${documentId}`)
      .toPromise();
  }

  sendAffiliateRequest(documentId: string, data: any) {
    return this.http
      .post(
        this.url + `/api/v1/Affiliates/${documentId}/sendAffiliatedRequest`,
        data
      )
      .toPromise();
  }

  updateAffilate(documentId: string, data: any) {
    return this.http
      .post(this.url + `/api/v1/Affiliates/${documentId}/update`, data)
      .toPromise();
  }

  getProfessionalsForAtentionOrder() {
    return Promise.all([
      this.http
        .get(
          this.url +
            `/api/v1/Services?affiliatedCompanyGroupPlanService=${this.affiliatedUser.affiliatedCompanyGroupPlanServiceId}`
        )
        .toPromise(),
      this.http.get(this.url + `/api/v1/States`).toPromise(),
    ]);
  }

  getProfessionalsForAtentionOrderChevere() {
    return Promise.all([
      this.http
        .get(
          this.url +
            `/api/v1/Services?affiliatedCompanyGroupPlanService=${this.affiliatedUser.affiliatedCompanyGroupPlanServiceId}&isChevere=True`
        )
        .toPromise(),
      this.http.get(this.url + `/api/v1/States`).toPromise(),
    ]);
  }

  getSpecialities(serviceCode: string) {
    return this.http
      .get(this.url + `/api/v1/Services/${serviceCode}/specialties`)
      .toPromise();
  }

  //Obtiene el listado de profesionales con filtro de especialidad
  getProfessionalsOrdenAtencion(
    serviceCode: string,
    stateCode: string,
    cityCode: string,
    specialtyCode: string,
    empresaContratante: string
  ) {
    return this.http
      .get(this.url + `/api/v1/Professionals`, {
        params: {
          serviceCode,
          stateCode,
          cityCode,
          specialtyCode,
          esChevere: empresaContratante === "J404854185" ? "true" : "false",
        },
      })
      .toPromise();
  }

  // emitir orden de atencion
  emitAtentionOrder(documentId: string, data: any) {
    return this.http
      .post(
        this.url + `/api/v1/Affiliates/${documentId}/atentionOrderIssue`,
        data
      )
      .toPromise();
    // .catch((err) => {
    //   return { message: err.error.message };
    // });
  }

  sendEmailOrderMX(documentId: string, data: any, order: string) {
    return this.http
      .post(
        this.url + `/api/v1/Affiliates/${documentId}/sendEmailAtentionOrderRX?order=${order}`,
        data
      )
      .toPromise();
    // .catch((err) => {
    //   return { message: err.error.message };
    // });
  }

  emitAtentionOrderRX(order: string, data: any) {
    return this.http
      .post(
        this.url + `/api/v1/AtentionOrders/${order}/uploadImagerx`,
        data
      )
      .toPromise();
    // .catch((err) => {
    //   return { message: err.error.message };
    // });
  }

  // tipos de razon de consulta
  getTypesReasonConsult(serviceCode: string) {
    return this.http
      .get(this.url + `/api/v1/Services/${serviceCode}/reasonsConsult`)
      .toPromise();
  }

  // envio de sugerencias
  sendSuggestion(documentId: string, typeUser: number, text: string) {
    return this.http.post(this.url + `/api/v1/Generals/send-suggestion`, {
      typeUser,
      text,
      documentId,
    });
  }

  //Obtiene el listado de profesionales con filtro de especialidad
  async validarOrdenAtencionCantidad(
    documentId: string,
  ) {
    try
    {
      await this.http
      .get(this.url + `/api/v1/Professionals/${documentId}/validateCantOA`, { })
      .toPromise();

      return "true:";
    }
    catch(e)
    {
      console.log(e);
      return "false:"+e.error;
    }
    
  }
}
