// Funcion que sirve para hacer lo opuesto a formatDocumentId()
// La cedula viene con el formato 00001234 desde el backend y
// no podemos mostrar en el front esos ceros que no agregan
// valor, por lo que debemos eliminarlos.
// Nota: solo se eliminan los primeros, al llegar a un numero
// se retorna el valor calculado.

export const deformatDocumentId = (documentId: string) => {
    return Number(documentId);
}
